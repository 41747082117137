import { Injectable } from '@angular/core';
import { DvmService } from '../services/dvm.service';

@Injectable()
export class SelectionAreaEndHandler {
  constructor(private dvmService: DvmService) {
    // CLICK subscribe
    this.dvmService.viewerSubject.subscribe({
      next: viewer => {
        viewer.subscribe('end_selection_area', this.handle.bind(this));
      },
      error: error => {
        console.error(error);
      },
    });
  }

  /**
   * Debemos invertir la seleccion:
   * 'available' -> 'selected' -> añadimos al cart
   * 'selected' -> borramos del cart -> pasa a 'selected'
   * https://mmcbcn.atlassian.net/browse/IT-2929
   * @param obj
   */
  protected handle(obj: any) {}
}
