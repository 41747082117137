import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceType',
})
export class PriceTypePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    let priceText = '';

    switch (value) {
      case 'ONE_YEAR': {
        priceText = '1 Year';
        break;
      }
      case 'THREE_YEARS': {
        priceText = '3 Years';
        break;
      }
      default: {
        //statements;
        break;
      }
    }

    return priceText;
  }
}
