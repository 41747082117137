import { DVMViewer3dInitializerOptions, DVMViewerInitializerOptions } from '@3ddv/dvm-internal';

export const inputOptions: DVMViewerInitializerOptions = {
  container: 'map-container',
  styles_by_groups: true,
  plugins: ['best_nodes', 'selection_area'],
};

export const inputOptions3d: DVMViewer3dInitializerOptions = {
  container: 'viewer3d-container', // Container where the viewer will be appended
  version: 'stable',
};

export const loadOptions3d = {
  venue_id: 'eu-gb-00040-basketball', // Venue to be loaded. 3ddigitalvenue will provide these IDs
};

export const loadOptions = {
  venue_id: 'eu-gb-00040-endstage', // Venue to be loaded. 3ddigitalvenue will provide these IDs
  map_id: 'blockmap', // Map id. "main" by default.
};

/* venue_id: 'eu-gb-00040-basketball',
map_id: 'blockmap' */
