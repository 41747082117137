import { Pipe, PipeTransform } from '@angular/core';
import { Price } from 'src/app/interfaces/transaction-response';

@Pipe({
  name: 'priceTypeArray',
})
export class PriceTypeArrayPipe implements PipeTransform {
  transform(prices: Price[], arg: string): number {
    let value: number = 0;

    prices.forEach(x => {
      if (x.type == arg) {
        value = x.value;
      }
    });
    return value;
  }
}
