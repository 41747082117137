import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Price, PriceType } from 'src/app/interfaces/transaction-response';
import { PriceTypeService } from 'src/app/services/price-type.service';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { PriceTypeArrayPipe } from 'src/app/shared/pipes/price-type-array.pipe';

@Component({
  selector: 'app-add-edit-price',
  templateUrl: './add-edit-price.component.html',
  styleUrls: ['./add-edit-price.component.scss'],
  providers: [PriceTypeArrayPipe],
})
export class AddEditPriceComponent implements OnInit {
  action: string = 'Add ';
  priceTypeObject: PriceType | null = null;
  priceTypeForm: FormGroup;

  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddEditPriceComponent>,
    @Inject(MAT_DIALOG_DATA) public priceTypeInput: PriceType,
    private fb: FormBuilder,
    private priceType: PriceTypeService,
    public dialog: MatDialog,
    private priceTypeArray: PriceTypeArrayPipe,
    private toastr: ToastrService
  ) {
    this.priceTypeForm = this.fb.group({
      priceName: ['', Validators.required],
      oneYearPrice: ['', [Validators.required]],
      threeYearPrice: ['', [Validators.required]],
    });

    this.priceTypeObject = priceTypeInput;
  }

  ngOnInit(): void {
    this.isEdit();
  }

  isEdit() {
    if (this.priceTypeObject !== null) {
      this.action = 'Edit ';

      this.priceTypeForm.patchValue({
        priceName: this.priceTypeObject.name,
        oneYearPrice: this.priceTypeArray.transform(this.priceTypeObject.prices, 'ONE_YEAR'),
        threeYearPrice: this.priceTypeArray.transform(this.priceTypeObject.prices, 'THREE_YEARS'),
      });
    }
  }

  save() {
    let getPrice: PriceType = this.getPriceType();
    this.loading = true;
    if (this.priceTypeObject !== null) {
      // Edit
      getPrice = this.setEditValues(getPrice);

      this.priceType.updatePriceType(getPrice, this.priceTypeObject.id!).subscribe({
        next: () => {
          this.dialogRef.close(true);
          this.loading = false;
          this.toastr.success('Price Type  was updated successfully', 'Updated');
        },
        error: (error: HttpErrorResponse) => {
          this.error(error);
          this.loading = false;
        },
      });
    } else {
      // Add
      this.priceType.addPriceType(getPrice).subscribe({
        next: () => {
          this.dialogRef.close(true);
          this.loading = false;
          this.toastr.success('Price Type  was added successfully', 'Add');
        },
        error: (error: HttpErrorResponse) => {
          this.error(error);
          this.loading = false;
        },
      });
    }
  }

  getPriceType(): PriceType {
    const inputPriceOneYear: number = this.priceTypeForm.value.oneYearPrice;
    const inputPriceThreeYear: number = this.priceTypeForm.value.threeYearPrice;
    const prices: Price[] = [];

    const oneYearPrice: Price = {
      type: 'ONE_YEAR',
      value: inputPriceOneYear,
    };

    const threeYearPrice: Price = {
      type: 'THREE_YEARS',
      value: inputPriceThreeYear,
    };

    prices.push(oneYearPrice);
    prices.push(threeYearPrice);

    const priceType: PriceType = {
      name: this.priceTypeForm.value.priceName,
      prices: prices,
    };

    return priceType;
  }

  back() {
    this.dialogRef.close();
  }

  delete(idPrice: number) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: '250px',
      data: 'price',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.priceType.deletePriceType(idPrice).subscribe(data => {
          this.dialogRef.close(true);
        });
      }
    });
  }

  setEditValues(getPrice: PriceType): PriceType {
    // Set idPrice
    getPrice.prices.forEach(x => {
      this.priceTypeObject?.prices.forEach(y => {
        if (x.type == y.type) {
          x.id = y.id;
          return;
        }
      });
    });

    return getPrice;
  }

  error(error: HttpErrorResponse) {
    const errorMsg: string = error?.error?.message
      ? error.error.message
      : 'An error has occurred, please contact the administrator';
    this.toastr.error(errorMsg, 'Error!');
  }
}
