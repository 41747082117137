import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Popover3dviewService {
  popoverTimerTime = 100; // ms
  popoverTimer: any;
  currentView: any;

  constructor() {}

  setTimer() {
    this.popoverTimer = setTimeout(() => {
      this.timerFunction();
    }, this.popoverTimerTime);
  }

  deleteTimer() {
    if (this.popoverTimer) {
      clearTimeout(this.popoverTimer);
    }
  }

  timerFunction() {
    const tooltip: HTMLElement = document.querySelector('#tooltip')!;
    tooltip.removeAttribute('data-show');
  }
}
