import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserLogin } from 'src/app/interfaces/login';
import { LoginResponse } from 'src/app/interfaces/login-response';
import { LoginService } from 'src/app/services/login.service';
import { VerifyLogin } from '../../interfaces/verify-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: FormGroup;
  verifyForm: FormGroup;
  loading: boolean = false;

  title: string = 'The O2 arena';
  copyright: string = 'Powered by 3DDV';

  showVerify: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private loginService: LoginService
  ) {
    this.loginForm = this.fb.group({
      account: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.verifyForm = this.fb.group({
      verifyCode: ['', Validators.required],
    });
  }

  logIn() {
    const username = this.loginForm.value.account;
    const password = this.loginForm.value.password;

    const user: UserLogin = {
      username,
      password,
    };

    this.loading = true;
    this.loginService.login(user).subscribe({
      next: any => {
        this.loading = false;
        this.showVerify = true;
      },
      error: (e: HttpErrorResponse) => {
        this.loading = false;
        if (e.error && e.error?.code == 'E_BAD_LOGIN') {
          this.toastr.error(e.error.message, 'Error!');
        } else {
          this.toastr.error('An error has occurred, please contact the administrator', 'Error!');
        }
        this.resetForm();
      },
    });
  }

  verify() {
    const username = this.loginForm.value.account;
    const password = this.loginForm.value.password;
    const oneTimePassword = this.verifyForm.value.verifyCode;

    const userVerification: VerifyLogin = {
      username,
      password,
      oneTimePassword,
    };

    this.loading = true;
    this.loginService.verify(userVerification).subscribe({
      next: userResponse => {
        this.setLocalStorage(userResponse);
        this.loading = true;
        this.router.navigate(['/layout']).then(() => {});
      },
      error: (e: HttpErrorResponse) => {
        this.loading = false;
        if (e.error && e.error?.code == 'E_OTP_EXPIRED_OR_INVALID') {
          this.toastr.error(e.error.message, 'Error!');
        } else {
          this.toastr.error('An error has occurred, please contact the administrator', 'Error!');
        }
      },
    });
  }

  setLocalStorage(loginResponse: LoginResponse) {
    localStorage.setItem('user', JSON.stringify(loginResponse.user));
    localStorage.setItem('token', loginResponse.token);
  }

  resetForm() {
    this.loginForm.setValue({
      account: '',
      password: '',
    });
  }
}
