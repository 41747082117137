import { MapViewerNode } from '@3ddv/dvm-internal';
import { Injectable } from '@angular/core';
import { SeatInfo } from 'src/app/interfaces/seat-info';
import { SeatManagementService } from 'src/app/services/seat-management.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { DvmService } from '../services/dvm.service';

@Injectable()
export class ClickHandler {
  booth: string = 'Booth';

  constructor(
    private dvmService: DvmService,
    private seatManagement: SeatManagementService,
    private utilitiesService: UtilitiesService
  ) {
    this.dvmService.viewerSubject.subscribe({
      next: (viewer: any) => {
        viewer.subscribe('click', this.handle.bind(this));
      },
      error: error => {
        console.error(error);
      },
    });
  }

  private handle(obj: any) {
    let node: MapViewerNode = obj.nodes[0];
    const mapId = this.dvmService.viewer.getMapId();

    if (node) {
      if (node.type === 'section' && (node.state === 'available' || node.state === 'selected') && mapId == 'blockmap') {
        const idNodeSection = node.id;
        this.dvmService.loadMap(idNodeSection);
      }
    }

    // CLICKING SEAT NODE
    if (node && node.type === 'seat') {
      const seatInfo: SeatInfo = this.utilitiesService.splittedSeatInfoFromId(node.id);
      if (node.state === 'available') {
        if (seatInfo.seat_row.includes(this.booth)) {
          // If the seat you wish to select is a cabin seat, you must select the rest of the row. https://mmcbcn.atlassian.net/browse/IT-3551
          node.row?.forEach(item => {
            // first check undefineds
            if (item) {
              const seatInfo: SeatInfo = this.utilitiesService.splittedSeatInfoFromId(item.toString());
              this.seatManagement.addSeat(seatInfo);
              // In order not to deselect the chair that the user has clicked on, we skip that chair in the dvm select.
              if (node.id !== item) {
                this.dvmService.viewer.select(item);
              }
            }
          });
        } else {
          // If the seat is not booth type, it works as usual.
          this.seatManagement.addSeat(seatInfo);
        }
      } else if (node.state === 'selected') {
        if (seatInfo.seat_row.includes(this.booth)) {
          // If the seat you wish to unselect is a booth seat, you must unselect the rest of the row. https://mmcbcn.atlassian.net/browse/IT-3551
          node.row?.forEach(item => {
            // first check undefineds
            if (item) {
              const seatInfo: SeatInfo = this.utilitiesService.splittedSeatInfoFromId(item.toString());
              this.seatManagement.deleteSeat(seatInfo);
              // In order not to select the chair that the user has clicked on, we skip that chair in the dvm select.
              if (node.id !== item) {
                this.dvmService.viewer.unselect(item);
              }
            }
          });
        } else {
          // If the seat is not booth type, it works as usual.
          this.seatManagement.deleteSeat(seatInfo);
        }
      }
    }
  }
}
