import { Injectable } from '@angular/core';
import { User } from '../interfaces/login-response';
import { HttpClient } from '@angular/common/http';
import { Observable, map, tap } from 'rxjs';
import { CountryDataModel } from '../interfaces/country-data';
import { environment } from 'src/environments/environment';

export interface MinimizedUser {
  id: number;
  username: string;
}

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  areaCodes: CountryDataModel[] = [];

  constructor(private http: HttpClient) {
    this.getListCountry().subscribe(data => {
      this.areaCodes = data;
    });
  }

  splittedSeatInfoFromId(seatId: string) {
    let splitedInfo = seatId.split('-');
    let seatInfo: {
      id: string;
      section: string;
      seat_row: string;
      seat: string;
    } = {
      id: seatId,
      section: splitedInfo[0],
      seat_row: splitedInfo[1],
      seat: splitedInfo[2],
    };
    return seatInfo;
  }

  // Extract one column of the object array
  extractColumn(array: any[], column: string): any[] {
    return array.map(x => x[column]);
  }

  // Input: ['S_115', 'S_104', 'S_104','S_104'] - Output ['S_115', 'S_104']
  removeDuplicates(array: string[]): any[] {
    return [...new Set(array)];
  }

  getAreaCode(phone: string | null): string {
    if (phone == null) {
      return '';
    }

    const lengthAreaCode = this.getLengthAreaCode(phone, this.areaCodes);

    return phone.substring(0, lengthAreaCode);
  }

  getPhone(phone: string | null): string {
    if (phone == null) {
      return '';
    }

    const lengthAreaCode = this.getLengthAreaCode(phone, this.areaCodes);

    return phone.slice(lengthAreaCode, phone.length);
  }

  getLengthAreaCode(phone: string, listAreaCode: CountryDataModel[]): number {
    let lengthAreaCode: number | undefined;

    // Verificamos si el codigo de area es de 3 caracteres
    const lengthFour = phone.substring(0, 4);
    listAreaCode.forEach(x => {
      if (x.phoneCode == lengthFour) {
        lengthAreaCode = 4;
      }
    });

    if (lengthAreaCode !== undefined) {
      return lengthAreaCode;
    }

    // Verificamos si el codigo de area es de 2 caracteres
    const lengthThree = phone.substring(0, 3);
    listAreaCode.forEach(x => {
      if (x.phoneCode == lengthThree) {
        lengthAreaCode = 3;
      }
    });

    if (lengthAreaCode !== undefined) {
      return lengthAreaCode;
    }

    // Verificamos si el codigo de area es de 1 caracteres
    const lengthTwo = phone.substring(0, 2);
    listAreaCode.forEach(x => {
      if (x.phoneCode == lengthTwo) {
        lengthAreaCode = 2;
      }
    });

    if (lengthAreaCode !== undefined) {
      return lengthAreaCode;
    }

    return 0;
  }

  getUser(): User | null {
    const user: User = JSON.parse(localStorage.getItem('user')!);
    return user;
  }

  getListCountry(): Observable<CountryDataModel[]> {
    const urlCountryJson: string = 'assets/data/countries-code.json';
    return this.http.get<CountryDataModel[]>(urlCountryJson);
  }

  /**
   * Request to API and recover all users (without sudo users).
   */
  getListUsers(): Observable<MinimizedUser[]> {
    const api = environment.apiUrl;
    return this.http.get<MinimizedUser[]>(`${api}users/?pagination=off`);
  }
}
