import { Injectable } from '@angular/core';
import { Inventory, Section } from '../interfaces/inventory-response';

/**
 * Service responsible for translating section names and IDs for inventory items and sections.
 * It maintains mappings between original names/IDs and their translations.
 */
@Injectable({
  providedIn: 'root',
})
export class TranslatorService {
  private nameMap: { [key: string]: string } = {
    TheResidence1: 'RES 1',
    TheResidence2: 'RES 2',
    TheResidence3: 'RES 3',
  };

  private idMap: { [key: string]: string } = {
    S_TheResidence1: 'RES 1',
    S_TheResidence2: 'RES 2',
    S_TheResidence3: 'RES 3',
  };

  /**
   * Translates a section name using the predefined nameMap.
   *
   * @param value The original section name to be translated.
   * @returns The translated section name if found in the nameMap; otherwise, undefined.
   */
  public translateSectionName(value: string) {
    return this.nameMap[value];
  }

  /**
   * Translates a section ID using the predefined idMap.
   *
   * @param value The original section ID to be translated.
   * @returns The translated section ID if found in the idMap; otherwise, undefined.
   */
  public translateSectionId(value: string) {
    return this.idMap[value];
  }

  /**
   * Translates the names of sections within an array of inventory items.
   *
   * @param items An array of Inventory objects whose section names are to be translated.
   */
  public translateHttpResponseItems(items: Inventory[]) {
    if (!items) return;
    for (let item of items) {
      const name = item.section.name;
      if (this.nameMap[name]) {
        item.section.name = this.translateSectionName(name);
      }
    }
  }

  /**
   * Translates the names of sections within an array of Section objects.
   *
   * @param sections An array of Section objects to be translated.
   */
  public translateHttpResponseSections(sections: Section[]) {
    if (!sections) return;
    for (let section of sections) {
      const name = section.name;
      if (this.nameMap[name]) {
        section.name = this.translateSectionName(name);
      }
    }
  }
}
