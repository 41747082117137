export const seatListAvailability: string[] = [
  'S_331R-5-8',
  'S_331R-5-7',
  'S_331R-5-6',
  'S_331R-5-5',
  'S_331R-5-4',
  'S_331R-5-3',
  'S_331R-5-2',
  'S_328R-6-15',
  'S_328R-6-16',
  'S_328R-6-17',
  'S_228-10-17',
  'S_228-10-18',
  'S_228-10-19',
  'S_222-13-15',
  'S_222-14-15',
  'S_222-15-15',
  'S_222-16-15',
  'S_221-11-10',
  'S_221-11-11',
  'S_221-11-12',
  'S_221-11-13',
  'S_221-11-14',
  'S_221-11-15',
  'S_221-11-16',
  'S_221-11-17',
  'S_117-9-10',
  'S_117-9-11',
  'S_117-9-12',
  'S_117-9-13',
  'S_117-9-14',
  'S_115-10-10',
  'S_115-10-11',
  'S_115-10-12',
  'S_115-10-13',
  'S_106-10-10',
  'S_106-10-11',
  'S_106-10-12',
  'S_106-10-13',
  'S_106-11-14',
  'S_106-12-13',
  'S_106-12-14',
  'S_106-12-15',
  'S_106-12-16',
  'S_105-8-8',
  'S_105-8-9',
  'S_104-7-11',
  'S_104-7-12',
  'S_104-7-13',
  'S_104-7-14',
  'S_104-7-15',
  'S_103-10-14',
  'S_103-10-15',
  'S_103-10-16',
  'S_103-10-17',
  'S_103-10-18',
  'S_103-10-19',
  'S_103-11-15',
  'S_103-11-16',
  'S_103-11-17',
  'S_102-10-15',
  'S_102-10-16',
  'S_102-10-17',
  'S_102-10-18',
  'S_102-11-18',
  'S_102-11-19',
];
