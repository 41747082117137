import { Pipe, PipeTransform } from '@angular/core';
import { Role } from 'src/app/interfaces/login-response';

@Pipe({
  name: 'nameRoles',
})
export class NameRolesPipe implements PipeTransform {
  transform(roles: Role[], ...args: unknown[]): string {
    let concatNames: string = '';

    roles.forEach((rol, index) => {
      // If we have more than one element we use '-' --> (Admin - Bussines)
      if (index == 0) {
        concatNames += `${rol.name} `;
      } else {
        concatNames += `- ${rol.name} `;
      }
    });

    return concatNames;
  }
}
