<div class="body">
  <div class="wrapper">
    <div class="d-flex justify-content-between">
      <div></div>
      <h5 class="title">Edit Transaction</h5>
      <div style="width: 20px">
        <a (click)="back()" class="back">
          <mat-icon *ngIf="!loading" aria-hidden="false" aria-label="Example home icon" fontIcon="close"> </mat-icon>
        </a>
        <mat-spinner *ngIf="loading" [diameter]="30"></mat-spinner>
      </div>
    </div>
    <app-seat-selected [listSeats]="listSeats"></app-seat-selected>
    <form [formGroup]="transactionForm" (ngSubmit)="save()">
      <div class="row mb-3">
        <div class="col-sm-6">
          <label class="form-label">First Name</label>
          <input
            type="text"
            formControlName="firstName"
            class="form-control"
            placeholder="Martina"
            [ngClass]="{
              'is-invalid':
                transactionForm.get('firstName')?.hasError('required') && transactionForm.get('firstName')?.touched
            }" />
          <span
            class="error"
            *ngIf="transactionForm.get('firstName')?.hasError('required') && transactionForm.get('firstName')?.touched">
            First Name is <strong>required</strong>
          </span>
        </div>
        <div class="col-sm-6">
          <label class="form-label">Last Name</label>
          <input
            type="text"
            formControlName="lastName"
            class="form-control"
            placeholder="Aliaga"
            [ngClass]="{
              'is-invalid':
                transactionForm.get('lastName')?.hasError('required') && transactionForm.get('lastName')?.touched
            }" />
          <span
            class="error"
            *ngIf="transactionForm.get('lastName')?.hasError('required') && transactionForm.get('lastName')?.touched">
            Last Name is <strong>required</strong>
          </span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <label class="form-label">Email</label>
          <input
            type="text"
            class="form-control"
            formControlName="email"
            placeholder="juan@gmail.com"
            [ngClass]="{
              'is-invalid':
                (transactionForm.get('email')?.hasError('required') && transactionForm.get('email')?.touched) ||
                (transactionForm.get('email')?.hasError('email') && transactionForm.get('email')?.touched)
            }" />
          <span
            class="error"
            *ngIf="transactionForm.get('email')?.hasError('required') && transactionForm.get('email')?.touched">
            Email is <strong>required</strong>
          </span>
          <span
            class="error"
            *ngIf="transactionForm.get('email')?.hasError('email') && transactionForm.get('email')?.touched">
            Email is <strong>not valid</strong>
          </span>
        </div>
        <div class="col-sm-6">
          <label class="form-label">Telephone</label>
          <div style="display: flex">
            <select formControlName="areaCode" class="form-select telephone">
              <option *ngFor="let item of codeAreas" [value]="item">
                {{ item }}
              </option>
            </select>
            <input
              type="text"
              formControlName="telephone"
              class="form-control"
              placeholder="Telephone"
              [ngClass]="{
                'is-invalid':
                  (transactionForm.get('telephone')?.hasError('required') &&
                    transactionForm.get('telephone')?.touched) ||
                  (transactionForm.get('telephone')?.hasError('pattern') && transactionForm.get('telephone')?.touched)
              }" />
          </div>
          <span
            class="error"
            *ngIf="transactionForm.get('telephone')?.hasError('required') && transactionForm.get('telephone')?.touched">
            Telephone is <strong>required</strong>
          </span>
          <span
            class="error"
            *ngIf="transactionForm.get('telephone')?.hasError('pattern') && transactionForm.get('telephone')?.touched">
            Telephone must have <strong>only numbers</strong>
          </span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-12">
          <label class="form-label">Company Name</label>

          <div class="input-group">
            <input type="text" formControlName="companyName" class="form-control" placeholder="Company" />
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-sm-6">
          <label class="form-label">Status</label>
          <select formControlName="status" class="form-select status">
            <ng-container *ngFor="let item of listStatus">
              <option [value]="item" *ngIf="transaction?.status !== 'CANCELLED'">
                {{ item | titlecase }}
              </option>
            </ng-container>
            <option [value]="'CANCELLED'" *ngIf="transaction?.status === 'CANCELLED'" disabled>Cancelled</option>
          </select>
        </div>
        <div class="col-sm-6">
          <label class="form-label">Total</label>

          <div class="input-group">
            <input
              type="text"
              formControlName="balance"
              class="form-control"
              placeholder="Total"
              appCurrencyInput
              [ngClass]="{
                'is-invalid':
                  transactionForm.get('balance')?.hasError('required') && transactionForm.get('balance')?.touched
              }" />
          </div>
          <span
            class="error"
            *ngIf="transactionForm.get('balance')?.hasError('required') && transactionForm.get('balance')?.touched">
            Total is <strong>required</strong>
          </span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-6">
          <label class="form-label">Date</label>
          <input type="text" class="form-control" formControlName="createAt" placeholder="User" />
        </div>
        <div class="col-sm-6">
          <label class="form-label">User</label>
          <input type="text" class="form-control" formControlName="user" placeholder="User" value="John Doe" />
        </div>
      </div>

      <mat-dialog-actions>
        <button type="button" (click)="delete()" class="btn btnDelete">
          <img src="../../../../assets/svg/delete.svg" alt="delete" />
          Cancel Transaction
        </button>
        <div>
          <button type="button" class="btn btnCancel" (click)="back()">Discard</button>
          <button type="submit" class="btn btn-secondary btnSave" [disabled]="transactionForm.invalid">Save</button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>
