import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService {
  private myAppUrl: string = environment.apiUrl;
  private myApiUrl: string = '';

  constructor(private http: HttpClient) {}

  getSectionAvailability(): Observable<any> {
    this.myApiUrl = 'sections';
    return this.http.get(`${this.myAppUrl}${this.myApiUrl}`);
  }

  getSeatsByIdSection(idSection: string): Observable<any> {
    this.myApiUrl = 'products/1/availability/';
    return this.http.get(`${this.myAppUrl}${this.myApiUrl}${idSection}`);
  }
}
