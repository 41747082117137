import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SeatInfo } from 'src/app/interfaces/seat-info';

interface detail {
  row: string;
  seatsList?: string[];
  seatsRanges?: string[];
  seat: string;
}

interface SeatsOrderBySection {
  section: string;
  detail: detail[];
}

@Component({
  selector: 'app-seat-selected',
  templateUrl: './seat-selected.component.html',
  styleUrls: ['./seat-selected.component.scss'],
})
export class SeatSelectedComponent implements OnChanges {
  @Input() listSeats!: SeatInfo[];
  listSection: SeatsOrderBySection[] = [];

  constructor() {}

  ngOnChanges(simpleChange: any) {
    this.initArray();
  }

  getTotalSeats(): number {
    return this.listSeats.length;
  }

  initArray() {
    const arrNames = Array.from(new Set(this.listSeats.map(x => x.section))); // make an array of unique names
    this.listSection = arrNames
      .map(x => this.listSeats.filter(y => y.section === x)) // filter by name
      .map((x, i) => ({
        section: arrNames[i],
        detail: x.map(y => ({ row: y.seat_row, seat: y.seat })),
      })); // make new objects

    this.groupByRow(this.listSection);
  }

  groupByRow(listSection: SeatsOrderBySection[]) {
    const listSectionMap: SeatsOrderBySection[] = [];

    listSection.forEach(x => {
      let detailList: detail[] = [];

      x.detail.forEach(y => {
        const repeatElement = detailList.find(element => element.row == y.row);
        if (repeatElement == undefined) {
          y.seatsList = [];
          y.seatsList?.push(y.seat);
          let detail: detail = y;
          detailList.push(detail);
        } else {
          repeatElement.seat = repeatElement.seat + ', ' + y.seat;
          repeatElement.seatsList?.push(y.seat);
        }
      });

      detailList.forEach(x => {
        x.seatsList = x.seatsList?.sort(this.compareNumbers);
      });

      let section: SeatsOrderBySection = {
        section: x.section,
        detail: detailList,
      };
      listSectionMap.push(section);
    });
    this.listSection = listSectionMap;
    this.addRangeSeats(listSectionMap);
  }

  addRangeSeats(listSectionMap: SeatsOrderBySection[]): SeatsOrderBySection[] {
    listSectionMap.forEach(section => {
      section.detail.forEach(x => {
        let ranges: any[] = [];
        let firstNum: number | undefined;
        let lastCurr: number | undefined;

        for (const seatNum of x.seatsList!) {
          const currNum = parseInt(seatNum, 10);

          // Si no existe firstNum
          if (!firstNum) {
            firstNum = currNum;
            lastCurr = currNum;

            // Si firstNum es el último num (sólo hay un seat seleccionado en la row)
            if (firstNum === Number(x.seatsList![x.seatsList!.length - 1])) {
              ranges.push(firstNum);
            }
            // Si ya hay firstNum
          } else {
            // Si hay num anterior y son consecutivos
            if (lastCurr! + 1 === currNum) {
              lastCurr = currNum;

              // Si es el último num
              if (currNum == Number(x.seatsList![x.seatsList!.length - 1])) {
                // Si el primer y el último num tienen una diferencia de 2 o más
                if (currNum - firstNum >= 2) {
                  ranges.push(firstNum + '-' + currNum);
                  // Si no
                } else {
                  ranges.push(firstNum);
                  ranges.push(currNum);
                }
              }
              // Si hay num anterior y NO son consecutivos
            } else if (lastCurr! + 1 !== currNum) {
              // Si el primer y el último num tienen una diferencia de 2 o más
              if (lastCurr! - firstNum >= 2) {
                ranges.push(firstNum + '-' + lastCurr);
                // Si no
              } else {
                // Si el primer num no era el último checkeado, es decir, si había dos nums consecutivos pero no suficientes para hacer un rango, se tienen que guardar los dos
                if (firstNum !== lastCurr) {
                  ranges.push(firstNum);
                }
                ranges.push(lastCurr);
                // console.log('Range false', ranges);
              }
              // Si es el último num
              if (currNum == Number(x.seatsList![x.seatsList!.length - 1])) {
                ranges.push(currNum);
              }
              firstNum = currNum;
              lastCurr = currNum;
            }
          }
        }

        x.seatsRanges = ranges;
      });
    });

    return listSectionMap;
  }

  // Sort by number
  compareNumbers(a: any, b: any) {
    return a - b;
  }
}
