import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfirmPassword } from '../components/reset-password/confirm-password-post';
import { ResetPasswordResponse } from '../components/reset-password/reset-password-response';
import { UserLogin } from '../interfaces/login';
import { LoginResponse } from '../interfaces/login-response';
import { VerifyLogin } from '../interfaces/verify-login';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private myAppUrl: string = environment.apiUrl;
  private myApiUrl: string = '';

  constructor(private http: HttpClient) {}

  login(user: UserLogin): Observable<any> {
    // any because don't care about the response
    this.myApiUrl = 'auth/login';
    return this.http.post<any>(`${this.myAppUrl}${this.myApiUrl}`, user);
  }

  verify(user: VerifyLogin): Observable<LoginResponse> {
    this.myApiUrl = 'auth/verify';
    return this.http.post<LoginResponse>(`${this.myAppUrl}${this.myApiUrl}`, user);
  }

  resetPassword(email: string): Observable<ResetPasswordResponse> {
    this.myApiUrl = 'auth/password-reset/';
    const body: any = {
      email,
    };
    return this.http.post<ResetPasswordResponse>(`${this.myAppUrl}${this.myApiUrl}`, body);
  }

  confirmPassword(confirmPassword: ConfirmPassword): Observable<ResetPasswordResponse> {
    this.myApiUrl = 'auth/password-reset/finalize';
    return this.http.post<ResetPasswordResponse>(`${this.myAppUrl}${this.myApiUrl}`, confirmPassword);
  }
}
