import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-transaction-expired',
  templateUrl: './transaction-expired.component.html',
  styleUrls: ['./transaction-expired.component.scss'],
})
export class TransactionExpiredComponent {
  constructor(public dialogRef: MatDialogRef<TransactionExpiredComponent>) {}

  close() {
    this.dialogRef.close();
  }
}
