import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ListTransactionResponse } from '../components/transaction-managment/list-transaction-response';
import { FilterGridTransaction } from '../components/transaction-managment/transaction-filter-grid';
import { Checkout } from '../interfaces/checkout';
import { Transaction } from '../interfaces/transaction';
import { TransactionResponse } from '../interfaces/transaction-response';
import { DatePipe } from '@angular/common';
import { SortBy } from '../components/transaction-managment/transaction-managment.component';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private myAppUrl: string = environment.apiUrl;
  private myApiUrl: string = 'transactions/';

  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  saveCheckout(checkout: Checkout): Observable<any> {
    return this.http.post(`${this.myAppUrl}${this.myApiUrl}`, checkout);
  }

  completeTransaction(transaction: Transaction, idTransaction: string): Observable<any> {
    return this.http.post(`${this.myAppUrl}${this.myApiUrl}${idTransaction}`, transaction);
  }

  getTransaction(id: string): Observable<TransactionResponse> {
    return this.http.get<TransactionResponse>(`${this.myAppUrl}${this.myApiUrl}${id}?relations=yes`);
  }

  updatePriceTransaction(id: string, length: string): Observable<TransactionResponse> {
    const priceUpdate: any = { length };
    return this.http.put<TransactionResponse>(`${this.myAppUrl}${this.myApiUrl}${id}`, priceUpdate);
  }

  getListTransaction(): Observable<ListTransactionResponse> {
    return this.http.get<ListTransactionResponse>(`${this.myAppUrl}${this.myApiUrl}`);
  }

  getFilterListTransaction(
    filter: FilterGridTransaction,
    sortBy?: SortBy | undefined
  ): Observable<ListTransactionResponse> {
    const filterString = this.filterSentence(filter);
    return this.http.get<ListTransactionResponse>(
      `${this.myAppUrl}${this.myApiUrl}${filterString}&sortby=${sortBy?.column}&sortdir=${sortBy?.direction}`
    );
  }

  deleteTransaction(id: string): Observable<TransactionResponse> {
    return this.http.delete<TransactionResponse>(`${this.myAppUrl}${this.myApiUrl}${id}`);
  }

  updateTransaction(id: string, transaction: any): Observable<TransactionResponse> {
    return this.http.patch<TransactionResponse>(`${this.myAppUrl}${this.myApiUrl}${id}`, transaction);
  }

  getInventoryExportCsv(filter: FilterGridTransaction): Observable<any> {
    const filterString = this.filterSentence(filter);

    return this.http.get(`${this.myAppUrl}${this.myApiUrl}export-to-csv/${filterString}`, {
      responseType: 'text' as 'text',
    });
  }

  filterSentence(filter: FilterGridTransaction): string {
    let filterString: string = '?';

    if (filter.status != 'Show All') {
      filterString += `status=${filter.status}`;
    }

    if (filter.id != '' && filter.id !== '0') {
      filterString += `id=${filter.id}`;
    }

    if (filter.user != 'Show All') {
      filterString += `&username=${filter.user}`;
    }

    if (filter.firstName != '') {
      filterString += `&firstName=${filter.firstName}`;
    }

    if (filter.lastName != '') {
      filterString += `&lastName=${filter.lastName}`;
    }

    if (filter.dateFrom != null) {
      const dateForm: string | null = this.datePipe.transform(filter.dateFrom, 'yyyy-MM-dd');
      filterString += `&dateFrom=${dateForm}`;
    }

    if (filter.dateTo != null) {
      const dateTo: string | null = this.datePipe.transform(filter.dateTo, 'yyyy-MM-dd');
      filterString += `&dateTo=${dateTo}`;
    }

    if (filter.page != null) {
      filterString += `&page=${filter.page}`;
    }

    return filterString;
  }
}
