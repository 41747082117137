export const sectionListAvailability: string[] = [
  'S_102',
  'S_103',
  'S_104',
  'S_105',
  'S_106',
  'S_115',
  'S_117',
  'S_221',
  'S_222',
  'S_228',
  'S_328R',
  'S_331R',
];
