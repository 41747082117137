import { Injectable } from '@angular/core';
import { DvmService } from '../services/dvm.service';
import { Popover3dviewService } from '../services/popover-3dview.service';
import { SeatPopoverComponent } from '../popovers/seat-popover/seat-popover.component';
import { Placement, createPopper } from '@popperjs/core';
import { MapViewerNode } from '@3ddv/dvm-internal';

@Injectable()
export class HoverHandler {
  tooltip!: HTMLElement;

  constructor(private dvmService: DvmService, private popover3dview: Popover3dviewService) {
    this.dvmService.viewerSubject.subscribe({
      next: viewer => {
        viewer.subscribe('enter', this.handle.bind(this));
      },
      error: error => {
        console.error(error);
      },
    });
  }

  private handle(obj: any) {
    if (
      obj.nodes.length &&
      obj.nodes[0].type === 'seat' &&
      (obj.nodes[0].state === 'available' || obj.nodes[0].state === 'selected')
    ) {
      this.popover3dview.deleteTimer();
      const node: MapViewerNode = obj.nodes[0];
      // Receive tooltip HTML Element could be done better todo
      this.tooltip = document.querySelector('#tooltip')!;
      this.popover3dview.currentView = node.id;
      // Create content HTML Element, clean previous popover and append
      this.dvmService.popoverPlacement.clear();
      let offset = [0, -10];
      offset = [0, 0];
      const cref = this.dvmService.popoverPlacement.createComponent<SeatPopoverComponent>(SeatPopoverComponent);
      cref.instance.seatId = node.id;

      // Calculate popover placement
      let placement: Placement;
      if (node.anchor.dom && node.anchor.dom[1] > document.getElementById('seat-selection')!.offsetHeight / 2) {
        placement = 'top';
      } else {
        placement = 'bottom';
      }

      // Initiate popover
      createPopper(node, this.tooltip, {
        placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset,
            },
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: [],
            },
          },
        ],
      });

      // Display popover
      this.tooltip.setAttribute('data-show', '');
    }
  }

  sectionPopoverContent(node: MapViewerNode): string {
    return node.id;
  }
  seatPopoverContent(node: MapViewerNode): string {
    return node.id;
  }
}
