import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nodeVenue',
})
export class NodeVenuePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    const isMmcId = value.startsWith('S_');
    if (isMmcId) {
      return value.substring(2);
    } else {
      return value;
    }
  }
}
