import { Injectable } from '@angular/core';
import { SeatInfo } from 'src/app/interfaces/seat-info';
import { AvailabilityService } from 'src/app/services/availability.service';
import { SeatManagementService } from 'src/app/services/seat-management.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { seatListAvailability } from 'src/assets/mocks/seatsMock';
import { sectionListAvailability } from 'src/assets/mocks/sectionsMock';
import { DvmService } from '../services/dvm.service';

@Injectable()
export class LoadHandler {
  constructor(
    private dvmService: DvmService,
    private seatManagement: SeatManagementService,
    private utilitiesServices: UtilitiesService,
    private availabilityService: AvailabilityService
  ) {
    // ENDLOAD subscribe
    this.dvmService.viewerSubject.subscribe({
      next: viewer => {
        viewer.subscribe('end_load', this.handle.bind(this));
      },
      error: error => {
        console.error(error);
      },
    });
  }

  private handle(obj: any) {
    const mapId = this.dvmService.viewer.getMapId();

    if (mapId === 'blockmap') {
      //* TOP VIEW
      this.dvmService.viewer.unselectAll();
      this.availabilityService.getSectionAvailability().subscribe(sections => {
        const sectionsId = this.utilitiesServices.extractColumn(sections, 'id');
        this.dvmService.viewer.setAvailability('section', sectionsId);
        this.setStyles();
        this.setListSeatsAndSectionsSelected();
      });
    } else {
      // Map view
      this.availabilityService.getSeatsByIdSection(mapId!).subscribe(listProducts => {
        this.setStyles();
        const productsId = this.utilitiesServices.extractColumn(listProducts, 'id');
        this.dvmService.viewer.setAvailability('seat', productsId);
        this.setListSeatsAndSectionsSelected();
      });
    }
  }

  setAvailability() {
    // Upload availability
    this.dvmService.viewer.setAvailability('section', sectionListAvailability);
    this.dvmService.viewer.setAvailability('seat', seatListAvailability);
  }

  setListSeatsAndSectionsSelected() {
    const seatsSelected = this.seatManagement.listSeatSelected;

    // Set sections selected
    let sectionIdDuplicate = this.utilitiesServices.extractColumn(seatsSelected, 'section');
    const sectionsId = this.utilitiesServices.removeDuplicates(sectionIdDuplicate);
    this.dvmService.viewer.select(sectionsId);

    // Set seats selected
    let seatsId = this.utilitiesServices.extractColumn(seatsSelected, 'id');
    this.dvmService.viewer.select(seatsId);
  }

  setStyles() {
    const mapId = this.dvmService.viewer.getMapId();
    console.log(mapId);
    if (mapId == 'blockmap') {
      this.dvmService.changeSectionStyles();
    } else {
      this.dvmService.setAdas();
      this.dvmService.changeSeatStyles();
    }
  }
}
