import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/services/login.service';
import { ConfirmPassword } from './confirm-password-post';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  email: string = '';
  password: string = '';
  repeatPassword: string = '';
  title: string = 'The O2 arena';
  copyright: string = 'Powered by 3DDV';

  token: string = '';
  isConfirmPassword: boolean = false;
  loading: boolean = false;

  constructor(
    private loginService: LoginService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.isConfirmPassword = false;
      if (params && params['t']) {
        this.isConfirmPassword = true;
        const token: string = params['t'];
        this.token = token;
      }
    });
  }

  reset() {
    if (this.email == '') {
      this.toastr.error('Email address is a field required', 'Error!');
      return;
    }
    this.loading = true;
    this.loginService.resetPassword(this.email).subscribe({
      next: data => {
        this.toastr.success(data.message, 'Info');
        this.loading = false;
        this.router.navigate(['/login']);
      },
      error: (error: HttpErrorResponse) => {
        this.error(error);
        this.loading = false;
      },
    });
  }

  savePassword() {
    if (this.password == '' || this.repeatPassword == '') {
      this.toastr.error('Password and repeat password are fields required ', 'Error!');
      return;
    }

    if (this.password != this.repeatPassword) {
      this.toastr.error('Password and repeat password shoud be equal', 'Error!');
      return;
    }

    const confirmPassword: ConfirmPassword = {
      password: this.password,
      token: this.token,
    };

    this.loading = true;
    this.loginService.confirmPassword(confirmPassword).subscribe({
      next: data => {
        this.toastr.success(data.message, 'Info');
        this.loading = false;
        this.router.navigate(['/login']);
      },
      error: (error: HttpErrorResponse) => {
        this.error(error);
        this.loading = false;
      },
    });
  }

  error(e: HttpErrorResponse) {
    // First we try to show any error in the array of errors
    if (e.error?.details) {
      const error: string = e.error.details[0].message;
      this.toastr.error(error, 'Error!');
      return;
    }

    // If we don't have 'e.error.details' we try to show the property message in the error object
    // if not we show a generic error
    if (e.error?.message) {
      const error: string = e.error.message;
      this.toastr.error(error, 'Error!');
    } else {
      this.toastr.error('An error has occurred, please contact the administrator', 'Error!');
    }
  }
}
