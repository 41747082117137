<div
  (mouseenter)="onEnter()"
  (mouseleave)="onLeave()"
  class="popover-size h-auto text-white bg-black rounded-4 overflow-hidden">
  <div class="w-100 height-thumb">
    <!-- <div class='tooltip-img' [style.background-image]="`url(`${tooltipData.img}`)`"></div> -->
    <div
      *ngIf="tooltipData.img"
      (click)="load3dView()"
      class="w-100 height-thumb bg-center bg-cover bg-no-repeat viewer3d-image"
      [style.background-image]="'url(' + tooltipData.img + ')'">
      <span>Click to open 3d view</span>
    </div>
    <div *ngIf="!tooltipData.img" class="w-full h-100 d-flex justify-content-center align-items-center">
      <div class="lds-dual-ring"></div>
    </div>
  </div>

  <div class="px-3 py-2">
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column">
        <span class="font-normal">Section: </span>
        <span class="font-normal">{{ tooltipData.section.split('S_')[1] }}</span>
      </div>
      <div class="d-flex flex-column">
        <span class="font-normal">Row: </span>
        <span class="font-normal text-center">{{ tooltipData.row }}</span>
      </div>
      <div class="d-flex flex-column">
        <span class="font-normal">Seat: </span>
        <span class="font-normal text-center">{{ tooltipData.seat }}</span>
      </div>
    </div>
  </div>
</div>

<!-- <div class="seat-popover-container">
  <div class="id">
    seat: {{seatData.mmcID}}
  </div>
  <div class="price"> -->
<!--The first one is the default one-->
<!-- seat: {{seatData.price[0].value}}
  </div>
</div> -->
