import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// Modulos
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

// Handlers
import { ClickHandler } from './digital-venue/handlers/click.handler';
import { HoverHandler } from './digital-venue/handlers/hover.handler';
import { LeaveHandler } from './digital-venue/handlers/leave.handler';
import { LoadHandler } from './digital-venue/handlers/load.handler';
import { SelectionAreaEndHandler } from './digital-venue/handlers/selection-area.handler';

// Componentes
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

// Others
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SeatPopoverComponent } from './digital-venue/popovers/seat-popover/seat-popover.component';
import { TokenInterceptor } from './utils/token.interceptor';

@NgModule({
  declarations: [AppComponent, LoginComponent, ResetPasswordComponent, SeatPopoverComponent],
  imports: [BrowserModule, AppRoutingModule, SharedModule, BrowserAnimationsModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // This might be used to translate sections on further request
    // { provide: HTTP_INTERCEPTORS, useClass: TranslatorInterceptor, multi: true },
    LoadHandler,
    HoverHandler,
    LeaveHandler,
    SelectionAreaEndHandler,
    ClickHandler,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
