import { Pipe, PipeTransform } from '@angular/core';
import { TranslatorService } from '../../services/translator.service';

@Pipe({
  name: 'translator',
  pure: true,
})
export class TranslatorPipe implements PipeTransform {
  constructor(private translatorService: TranslatorService) {}

  /**
   * Translates text strings using the `TranslatorService`.
   * It attempts to translate a given text string by section ID or section name.
   * If no translation is found, it returns the original text.
   *
   * @param value ID or name to be translated
   */
  transform(value: string): string {
    return (
      this.translatorService.translateSectionId(value) || this.translatorService.translateSectionName(value) || value
    );
  }
}
