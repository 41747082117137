import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import * as pack from 'package.json';
import { User } from 'src/app/interfaces/login-response';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { Menu } from './menu';
import { navbarData } from './nav-data';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('350ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('350ms', style({ opacity: 0 }))]),
    ]),
    trigger('rotate', [
      transition(':enter', [
        animate(
          '1000ms',
          keyframes([
            style({ transform: 'rotate(0deg)', offset: '0' }),
            style({ transform: 'rotate(2turn)', offset: '1' }),
          ])
        ),
      ]),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  @Output() toggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  collapsed = false;
  screenWidth = 0;
  /* navData: Menu[] = navbarData; */
  navData: Menu[] = [];

  mode: string;
  pack = pack;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 768) {
      this.collapsed = false;
      this.toggleSideNav.emit({
        collapsed: this.collapsed,
        screenWidth: this.screenWidth,
      });
    }
  }

  constructor(private router: Router, private utilitiesService: UtilitiesService) {
    this.mode = 'blue';
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.listenRoute();
    this.getMenu();
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    this.toggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
  }

  closeSidenav(): void {
    this.collapsed = false;
    this.toggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
  }

  listenRoute() {
    this.router.events.subscribe(event => {
      if (event instanceof Scroll) {
        const checkout = event.routerEvent.url.includes('checkout');
        const seatSelection = event.routerEvent.url.includes('seat-selection');
        this.mode = checkout || seatSelection ? 'black' : 'blue';
      }
    });
  }

  getMenu() {
    const role: string = this.getUser()?.role.code || '';
    this.navData = navbarData;

    // Bussiness or account manager can't acess to the price screen
    if (role == 'BSNS' || role == 'MNGR') {
      this.navData = this.navData.filter(x => x.label != 'Prices');
      this.navData = this.navData.filter(x => x.label != 'Inventory');
    }
  }

  getUser(): User | null {
    const user: User | null = this.utilitiesService.getUser();
    return user;
  }
}
