<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="body">
  <div class="wrapper">
    <div class="d-flex justify-content-between">
      <div></div>
      <h5 class="title">{{ action }} Prices Type</h5>
      <div style="width: 20px; padding-top: 10px">
        <a (click)="back()" class="back">
          <mat-icon *ngIf="!loading" aria-hidden="false" aria-label="Example home icon" fontIcon="close"> </mat-icon>
        </a>
        <mat-spinner *ngIf="loading" [diameter]="30"></mat-spinner>
      </div>
    </div>
    <form [formGroup]="priceTypeForm" (ngSubmit)="save()">
      <div class="row mt-5">
        <div class="col-sm-6 offset-md-3">
          <label class="form-label">Price Type name</label>
          <input type="text" formControlName="priceName" class="form-control" placeholder="A" />
          <span
            class="error"
            *ngIf="priceTypeForm.get('priceName')?.hasError('required') && priceTypeForm.get('priceName')?.touched">
            Price Type name is <strong>required</strong>
          </span>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm-6">
          <label class="form-label">1 Year price</label>
          <div class="input-group mb-1">
            <input
              type="text"
              appCurrencyInput
              formControlName="oneYearPrice"
              class="form-control"
              placeholder="500.00" />
          </div>
          <span
            class="error"
            *ngIf="
              priceTypeForm.get('oneYearPrice')?.hasError('required') && priceTypeForm.get('oneYearPrice')?.touched
            ">
            1 Year price is <strong>required</strong>
          </span>
          <span
            class="error"
            *ngIf="
              priceTypeForm.get('oneYearPrice')?.hasError('pattern') && priceTypeForm.get('oneYearPrice')?.touched
            ">
            1 Year price must have <strong>only numbers</strong>
          </span>
        </div>
        <div class="col-sm-6">
          <label class="form-label">3 Year price</label>

          <div class="input-group mb-1">
            <input
              type="text"
              appCurrencyInput
              formControlName="threeYearPrice"
              class="form-control"
              placeholder="800.00" />
          </div>
          <span
            class="error"
            *ngIf="
              priceTypeForm.get('threeYearPrice')?.hasError('required') && priceTypeForm.get('threeYearPrice')?.touched
            ">
            3 Year price is <strong>required</strong>
          </span>
          <span
            class="error"
            *ngIf="
              priceTypeForm.get('threeYearPrice')?.hasError('pattern') && priceTypeForm.get('threeYearPrice')?.touched
            ">
            3 Year price must have <strong>only numbers</strong>
          </span>
        </div>
      </div>
      <mat-dialog-actions>
        <div>
          <button
            (click)="delete(priceTypeObject.id!)"
            *ngIf="priceTypeObject != null"
            type="button"
            class="btn btnCancel">
            <img src="../../../../assets/svg/delete.svg" alt="delete" />
            Delete Price Type
          </button>
        </div>

        <div>
          <button type="button" (click)="back()" class="btn btnBack">Discard</button>
          <button type="submit" class="btn btn-secondary btnSave" [disabled]="priceTypeForm.invalid">Save</button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>
