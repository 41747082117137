import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

// Modulos
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

// Angular Material
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';

// Pipes
import { NameRolesPipe } from './pipes/name-roles.pipe';
import { NodeVenuePipe } from './pipes/node-venue.pipe';
import { PriceTypeArrayPipe } from './pipes/price-type-array.pipe';

// Componentes
import { MatNativeDateModule } from '@angular/material/core';
import { TransactionExpiredComponent } from '../components/checkout/transaction-expired/transaction-expired.component';
import { AddEditPriceComponent } from '../components/price-type-management/add-edit-price/add-edit-price.component';
import { DetailsTransactionComponent } from '../components/transaction-managment/details-transaction/details-transaction.component';
import { CurrencyInputDirective } from '../utils/currency-input.directive';
import { InputOnlyNumbersDirective } from '../utils/input-only-numbers.directive';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { LogoutComponent } from './logout/logout.component';
import { PriceTypePipe } from './pipes/price-type.pipe';
import { TranslatorPipe } from './pipes/translator.pipe';
import { SeatSelectedComponent } from './seat-selected/seat-selected.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TimerComponent } from './timer/timer.component';

// Directivas

@NgModule({
  declarations: [
    NodeVenuePipe,
    TranslatorPipe,
    SeatSelectedComponent,
    DetailsTransactionComponent,
    SpinnerComponent,
    TimerComponent,
    TransactionExpiredComponent,
    LogoutComponent,
    PriceTypePipe,
    DialogConfirmComponent,
    AddEditPriceComponent,
    PriceTypeArrayPipe,
    NameRolesPipe,
    CurrencyInputDirective,
    InputOnlyNumbersDirective,
  ],
  providers: [DatePipe, CurrencyPipe, TranslatorPipe],
  imports: [
    CommonModule,
    MatTableModule,
    MatChipsModule,
    MatIconModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatCardModule,
    MatDialogModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule,
    MatButtonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
  ],
  exports: [
    MatTableModule,
    MatChipsModule,
    MatIconModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatCardModule,
    MatDialogModule,
    ReactiveFormsModule,
    HttpClientModule,
    NodeVenuePipe,
    TranslatorPipe,
    SeatSelectedComponent,
    DetailsTransactionComponent,
    SpinnerComponent,
    TimerComponent,
    TransactionExpiredComponent,
    LogoutComponent,
    PriceTypePipe,
    FormsModule,
    DialogConfirmComponent,
    MatButtonModule,
    AddEditPriceComponent,
    PriceTypeArrayPipe,
    NameRolesPipe,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    CurrencyInputDirective,
    InputOnlyNumbersDirective,
    MatExpansionModule,
  ],
})
export class SharedModule {}
