import { Component, OnInit } from '@angular/core';
import { DvmService } from '../../services/dvm.service';
import { Popover3dviewService } from '../../services/popover-3dview.service';

@Component({
  selector: 'app-seat-popover',
  templateUrl: './seat-popover.component.html',
  styleUrls: ['./seat-popover.component.scss'],
})
export class SeatPopoverComponent implements OnInit {
  tooltipData!: { section: string; row: string; seat: string; img: string };
  seatId!: string;

  constructor(private dvm: DvmService, private popover3dview: Popover3dviewService) {}

  ngOnInit() {
    const [section, row, seat] = this.seatId.split('-');
    this.tooltipData = {
      section: section,
      row: row,
      seat: seat,
      img: '',
    };

    this.dvm.viewer
      .getThumbnail(this.seatId)
      .then((img: any) => {
        this.tooltipData.img = img.src;
      })
      .catch(err => {
        console.error(err);
      });
  }

  load3dView() {
    const tooltip: HTMLElement = document.querySelector('#tooltip')!;
    tooltip.removeAttribute('data-show');
    this.popover3dview.deleteTimer();
    this.dvm.load3dView(this.seatId);
  }

  onEnter() {
    this.popover3dview.deleteTimer();
  }

  onLeave() {
    this.popover3dview.setTimer();
  }
}
