import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { User } from '../interfaces/login-response';
import { UtilitiesService } from '../services/utilities.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private utilitiesService: UtilitiesService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user: User | null = this.utilitiesService.getUser();
    if (user) {
      return true;
    } else {
      this.router.navigate(['/login']);
    }
    return false;
  }
}
