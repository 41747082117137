import { loadModule, MapViewer, Viewer3d } from '@3ddv/dvm-internal';
import { Injectable, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslatorService } from '../../services/translator.service';
import { inputOptions, inputOptions3d, loadOptions, loadOptions3d } from './dvm.constants';

@Injectable({
  providedIn: 'root',
})
export class DvmService {
  viewer!: MapViewer;
  viewer3d!: Viewer3d;
  viewerSubject: Subject<any> = new Subject<any>();
  popoverPlacement!: ViewContainerRef;

  isSectionMap: boolean = false;
  is3dOpen: boolean = false;
  mapId: string = '';

  styles: any;

  loadOptionsInput: any;

  private adaSeatList: string[] = [
    'S_TheResidence1-D-W1',
    'S_TheResidence1-D-W2',
    'S_TheResidence1-D-W3',
    'S_TheResidence1-D-W4',
  ];

  constructor(private translatorService: TranslatorService) {}

  initializeDVM() {
    loadModule('map_viewer', inputOptions)
      .then(viewer => {
        (window as any).viewer = viewer;
        this.viewer = viewer;
        this.viewerSubject.next(viewer);

        this.loadMap();
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  loadMap(mapId?: string, venueId?: string) {
    this.loadOptionsInput = JSON.parse(JSON.stringify(loadOptions));
    this.isSectionMap = false;
    // We change venueId
    if (venueId) {
      this.loadOptionsInput.venue_id = venueId;
    }

    // Click in any section - We load 3D View
    if (mapId) {
      this.loadOptionsInput.map_id = mapId;
      this.mapId = this.translatorService.translateSectionId(mapId) || mapId;
      this.isSectionMap = true;
    }

    return this.viewer
      .loadMap(this.loadOptionsInput)
      .then(() => {
        this.viewer.flags.fixed_aspect_ratio = false;
        this.viewer.flags.max_zoom_on_first_limit = true;
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  load3dView(viewId: string) {
    const finalize = () => {
      this.setsVenueId3d();

      const loadOptions = JSON.parse(JSON.stringify(loadOptions3d));
      loadOptions.view_id = viewId;
      return this.viewer3d.loadView3d(loadOptions).then(() => {
        this.viewer3d.flags.fixed_aspect_ratio = false;
        this.is3dOpen = true;
        this.viewer3d.interface.setLabelPosition('bottomright');
      });
    };
    if (!this.viewer3d) {
      return loadModule('3d_viewer', inputOptions3d).then(viewer3d => {
        (window as any).viewer3d = viewer3d;
        this.viewer3d = viewer3d;
        return finalize();
      });
    } else {
      return finalize();
    }
  }

  close3dView() {
    if (this.viewer3d) {
      this.is3dOpen = false;
    }
  }

  setsVenueId3d() {
    const venueId = this.viewer.getVenueId();
    loadOptions3d.venue_id = venueId!;
  }

  changeSectionStyles(tags?: string) {
    this.styles = this.viewer.getStyles();

    this.styles[0].section.available.normal.none.fillStyle = '#288B5D'; // Blue light
    this.styles[0].section.available.normal.none['fill-opacity'] = 1; // Blue light
    this.styles[0].section.available.normal.none.strokeStyle = '#288B5D'; // Blue
    this.styles[0].section.available.hover.none.fillStyle = '#288B5D'; // Blue
    this.styles[0].section.available.hover.none['fill-opacity'] = 0.7; // Blue light
    this.styles[0].section.available.hover.none.strokeStyle = '#FFFFFF'; // White

    this.styles[0].section.selected.normal.none.fillStyle = '#7A40B9'; // Purple
    this.styles[0].section.selected.normal.none.strokeStyle = '#7A40B9'; // Purple
    this.styles[0].section.selected.hover.none.strokeStyle = '#FFFFFF'; // White

    let group1 = JSON.parse(JSON.stringify(this.styles[0].section.available.normal.none));
    group1.fillStyle = '#FF4D4D'; // Red (Def selected color)
    group1.strokeStyle = '#FF4D4D'; // Red (Def selected color)
    this.styles[0].section.available.normal['group1'] = group1;

    this.viewer.setStyles(this.styles);
  }

  changeSeatStyles() {
    this.styles = this.viewer.getStyles();

    this.styles[0].seat.available.normal.pending.fillStyle = 'orange';
    this.styles[0].seat.available.normal.pending.strokeStyle = 'orange';
    this.styles[0].seat.selected.normal.disabled.icon = 'icon-check';

    this.viewer.setStyles(this.styles);
  }

  setAdas(): void {
    this.viewer.setNodesTag([...this.adaSeatList], 'disabled');
  }
}
