import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit, OnDestroy {
  @Input() _createdTime!: string | undefined;
  timerObservable = new Subject<string>();
  @Output() transactionExpired = new EventEmitter<boolean>();

  transactionTimerLimit = 20;
  isTimerOver = false;

  interval!: any;
  timer!: any;

  ngOnInit(): void {
    this.timerObservable.subscribe(timer => {
      this.timer = timer;
    });
    this.setTimer();
    this.setTimerInterval();
  }

  ngOnDestroy() {
    this.timerObservable.unsubscribe();
    clearInterval(this.interval);
  }

  get getTimer() {
    return this.timer;
  }

  setTimerInterval(): void {
    this.interval = setInterval(() => {
      this.setTimer();
    }, 1000);
  }

  setTimer(): void {
    if (this._createdTime == undefined) {
      return;
    }
    // Gets the appointment date
    const date = this._createdTime;
    // Gets the timestamp of appointment date
    const createdTime = new Date(date).getTime();
    // Gets the current date
    const now = new Date().getTime();
    // Gets the difference between current date and appointment date
    let diff = (this.transactionTimerLimit * 60000 - (now - createdTime)) / 1000;
    if (diff < 0) {
      diff = 0;
      // Control so it pops one time
      if (!this.isTimerOver) {
        this.transactionExpiredModal();
        this.isTimerOver = true;
      }
    }
    // Parses the difference result
    const timeString = this.time2string(diff);
    // Updates the subject with the new value
    this.timerObservable.next(timeString);
  }

  transactionExpiredModal() {
    this.transactionExpired.emit(true);
  }

  time2string(timeStamp: any) {
    // Days
    const days = Math.floor(timeStamp / 86400);
    timeStamp -= days * 86400;
    // Hours
    const hoursNum = Math.floor(timeStamp / 3600) % 24;
    const hours = (hoursNum < 10 ? '0' : '') + hoursNum;
    timeStamp -= hoursNum * 3600;
    // Minutes
    const minutesNum = Math.floor(timeStamp / 60) % 60;
    const minutes = (minutesNum < 10 ? '0' : '') + minutesNum;
    timeStamp -= minutesNum * 60;
    // Seconds
    const secondsNum = Math.floor(timeStamp % 60);
    const seconds = (secondsNum < 10 ? '0' : '') + secondsNum;
    return minutes + ':' + seconds;
  }
}
