import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DvmService } from '../digital-venue/services/dvm.service';
import { SeatInfo } from '../interfaces/seat-info';
import { TranslatorService } from './translator.service';

@Injectable({
  providedIn: 'root',
})
export class SeatManagementService {
  private seatsSelected$ = new BehaviorSubject<SeatInfo[]>([]);
  listSeatSelected: SeatInfo[] = [];

  viewer: any;

  constructor(private dvmService: DvmService, private translatorService: TranslatorService) {
    this.dvmService.viewerSubject.subscribe({
      next: response => (this.viewer = response),
      error: error => {
        console.error(error);
      },
    });
  }

  addSeat(seat: SeatInfo) {
    this.listSeatSelected.push(seat);
    this.seatsSelected$.next(Object.assign([], this.listSeatSelected));
  }

  deleteSeat(seatInput: SeatInfo) {
    // Set like availability in the map

    this.listSeatSelected = this.listSeatSelected.filter(x => x.id != seatInput.id);
    this.seatsSelected$.next(Object.assign([], this.listSeatSelected));
  }

  removeAll() {
    // Set list like availability in the map

    this.listSeatSelected = [];
    this.seatsSelected$.next(Object.assign([], this.listSeatSelected));
  }

  getListSeatSelected(): Observable<SeatInfo[]> {
    return this.seatsSelected$.asObservable();
  }

  overrideList(listSeat: SeatInfo[]) {
    this.listSeatSelected = [...listSeat];
    this.seatsSelected$.next(Object.assign([], this.listSeatSelected));
  }
}
