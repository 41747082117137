<div class="body mt-2">
  <div class="sectionsSeats seat">
    <img src="../../../assets/svg/computer.svg" alt="computer" />
    <span class="amount">{{ getTotalSeats() }}</span>
  </div>

  <div class="sectionRow">
    <div class="section" *ngFor="let sectionSeat of listSection">
      <div class="sectionsSeats">
        <span> Section:&nbsp; </span>
        <span class="sectionText">
          <strong>
            {{ sectionSeat.section | translator | nodeVenue }}
          </strong>
        </span>
      </div>
      <div style="flex-grow: 3">
        <div class="rowItem sectionsSeats" *ngFor="let item of sectionSeat.detail">
          <span style="flex: 1; text-align: center">
            Row: <span class="sectionText"> {{ item.row }} </span>
          </span>
          <span style="flex: 2">
            Seat: <span class="sectionText"> {{ item.seatsRanges!.join(', ') }} </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
