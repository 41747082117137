<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="loading ? 'wrapperSpinner' : 'wrapper'" class="wrapper">
  <div class="loginImg">
    <img class="logo" src="../../../assets/img/logo.png" alt="Logo Oz" />
  </div>
  <form class="loginWrapper">
    <h3 class="text-center o2Arena">{{ title }}</h3>

    <ng-container *ngIf="!isConfirmPassword; then sendEmailForm; else confirmPassword"> </ng-container>

    <ng-template #sendEmailForm>
      <div class="loginForm">
        <div class="title">
          <h3 class="login">Reset password</h3>
        </div>
        <div class="mb-3 input">
          <label for="account" class="form-label">Email</label>
          <input
            type="text"
            [(ngModel)]="email"
            class="form-control form-control-lg"
            id="account"
            placeholder="maria@gmail.com"
            autocomplete="off"
            name="email" />
        </div>
        <button type="submit" (click)="reset()" class="btn btn-primary btn-lg mt-3 btnLogin">Send reset link</button>
        <a class="back" routerLink="/login">Back</a>
      </div>
    </ng-template>

    <ng-template #confirmPassword>
      <div class="loginForm">
        <div class="title">
          <h3 class="login">Confirm password</h3>
        </div>
        <div class="mb-3 input">
          <label for="password" class="form-label">Password</label>
          <input
            type="password"
            [(ngModel)]="password"
            class="form-control form-control-lg"
            id="password"
            placeholder="********"
            autocomplete="off"
            name="password" />
        </div>
        <div class="mb-3 input">
          <label for="repeatPassword" class="form-label">Repeat Password</label>
          <input
            type="password"
            [(ngModel)]="repeatPassword"
            class="form-control form-control-lg"
            placeholder="********"
            id="repeatPassword"
            autocomplete="off"
            name="password" />
        </div>
        <button type="submit" (click)="savePassword()" class="btn btn-primary mt-2 btn-lg btnLogin">Save</button>
      </div>
    </ng-template>
    <div>
      <p class="powered">{{ copyright }}</p>
    </div>
  </form>
</div>
