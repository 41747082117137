import { Menu } from './menu';

export const navbarData: Menu[] = [
  {
    routeLink: 'dashboard',
    icon: 'fal fa-home',
    label: 'Home',
  },
  {
    routeLink: 'inventory',
    icon: 'fal fa-inventory',
    label: 'Inventory',
  },
  {
    routeLink: 'seat-selection',
    icon: 'fal fa-briefcase',
    label: 'Premium Seat tool',
  },
  {
    routeLink: 'transactions',
    icon: 'fal fa-credit-card',
    label: 'Transactions',
  },
  {
    routeLink: 'price-type',
    icon: 'fal fa-tags',
    label: 'Prices',
  },
];
