import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appInputOnlyNumbers]',
})
export class InputOnlyNumbersDirective {
  private regexInt = /^\d+$/;
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];
  inputElement: HTMLInputElement;
  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  // variable to store last valid input
  private lastValid = '';
  @HostListener('input', ['$event'])
  onInput(event: any) {
    const cleanValue = (event.target.value.match(this.regexInt) || []).join('');
    if (cleanValue || !event.target.value) this.lastValid = cleanValue;
    this.inputElement.value = cleanValue || this.lastValid;
  }
}
