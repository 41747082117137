import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PriceType } from '../interfaces/transaction-response';

@Injectable({
  providedIn: 'root',
})
export class PriceTypeService {
  private myAppUrl: string = environment.apiUrl;
  private myApiUrl: string = 'price-types/';

  constructor(private http: HttpClient) {}

  getPriceTypes(): Observable<PriceType[]> {
    return this.http.get<PriceType[]>(`${this.myAppUrl}${this.myApiUrl}`);
  }

  deletePriceType(id: number): Observable<void> {
    return this.http.delete<void>(`${this.myAppUrl}${this.myApiUrl}${id}`);
  }

  addPriceType(priceType: PriceType): Observable<PriceType> {
    return this.http.post<PriceType>(`${this.myAppUrl}${this.myApiUrl}`, priceType);
  }

  updatePriceType(priceType: PriceType, idPrice: number): Observable<PriceType> {
    return this.http.patch<PriceType>(`${this.myAppUrl}${this.myApiUrl}${idPrice}`, priceType);
  }
}
