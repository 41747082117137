<div
  class="sidenav"
  [ngClass]="collapsed ? 'sidenav-collapsed' : ''"
  [ngStyle]="{ 'background-color': mode === 'blue' ? '#000562' : '#000000' }">
  <div style="text-align: center">
    <img routerLink="/layout/dashboard" class="logoImg mb-auto" src="../../../../assets/img/logo.png" alt="Logo Oz" />
  </div>
  <ul class="sidenav-nav">
    <li class="sidenav-nav-item" *ngFor="let data of navData">
      <a
        [ngClass]="mode === 'blue' ? '' : 'black'"
        class="sidenav-nav-link"
        [routerLink]="[data.routeLink]"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <i class="sidenav-link-icon" [class]="data.icon"></i>
        <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">
          {{ data.label }}
        </span>
      </a>
    </li>
  </ul>
  <div class="logo-container">
    <button [ngClass]="mode === 'blue' ? 'blue' : 'black'" *ngIf="!collapsed" class="logo" (click)="toggleCollapse()">
      <i @fadeInOut class="fal fa-angle-right"></i>
    </button>
    <button [ngClass]="mode === 'blue' ? 'blue' : 'black'" *ngIf="collapsed" class="logo" (click)="toggleCollapse()">
      <i @fadeInOut class="fal fa-angle-left"></i>
    </button>
    <div @fadeInOut *ngIf="collapsed" class="version-container">{{ pack.version }}</div>
  </div>
</div>
