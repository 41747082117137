import { CurrencyPipe, DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SeatInfo } from 'src/app/interfaces/seat-info';
import { NodeSeat, TransactionResponse } from 'src/app/interfaces/transaction-response';
import { TransactionService } from 'src/app/services/transaction.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { ListStatus } from '../../../utils/list-status';
import { CountryDataModel } from 'src/app/interfaces/country-data';

@Component({
  selector: 'app-details-transaction',
  templateUrl: './details-transaction.component.html',
  styleUrls: ['./details-transaction.component.scss'],
})
export class DetailsTransactionComponent {
  transaction!: TransactionResponse;
  listStatus: string[] = ListStatus;
  codeAreas: string[] = [];
  idTransaction: string = '';

  transactionForm: FormGroup;
  listSeats: SeatInfo[] = [];

  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DetailsTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) public id: string,
    private transactionService: TransactionService,
    private fb: FormBuilder,
    private utilitiesService: UtilitiesService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private currencyPipe: CurrencyPipe
  ) {
    this.idTransaction = id;
    this.transactionForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      createAt: [{ value: '', disabled: true }],
      user: [{ value: '', disabled: true }, Validators.required],
      areaCode: ['', Validators.required],
      telephone: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      status: ['', Validators.required],
      balance: ['', Validators.required],
      companyName: [''],
    });
    this.getTransaction(id);
    this.getCountries();
  }

  getTransaction(id: string) {
    this.loading = true;
    this.transactionService.getTransaction(id).subscribe({
      next: data => {
        this.transaction = data;
        this.mapSeats([...data.seats]);
        this.setValuesForm(this.transaction);
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.error(error);
      },
    });
  }

  mapSeats(seats: NodeSeat[]) {
    const seatList: SeatInfo[] = [];
    seats.forEach(x => {
      const seat: SeatInfo = this.utilitiesService.splittedSeatInfoFromId(x.seatId);
      seatList.push(seat);
    });
    this.listSeats = [...seatList];
  }
  back() {
    this.dialogRef.close(false);
  }

  setValuesForm(transaction: TransactionResponse) {
    const createAt = this.dateCreateAtPipe(transaction.createdAt);

    const areaCode: string = this.utilitiesService.getAreaCode(transaction.phone);
    console.log(areaCode);
    const phone: string = this.utilitiesService.getPhone(transaction.phone);
    const balance: string = this.currencyPipe.transform(transaction.balance, 'GBP')!;
    this.transactionForm.patchValue({
      firstName: transaction.firstName,
      lastName: transaction.lastName,
      email: transaction.email,
      areaCode,
      createAt,
      telephone: phone,
      balance: balance,
      status: transaction.status,
      user: transaction.user.username,
      companyName: transaction.companyName,
    });
  }

  delete() {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: '250px',
      data: 'transaction',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.transactionService.deleteTransaction(this.idTransaction).subscribe({
          next: () => {
            this.dialogRef.close(false);
            this.toastr.success('Transaction was cancelled successfully', 'Cancelled');
          },
          error: (error: HttpErrorResponse) => {
            this.error(error);
          },
        });
      }
    });
  }

  dateCreateAtPipe(date: Date): string | null {
    let datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'MM/dd/yyyy');
  }

  save() {
    let balance: string = this.transactionForm.value.balance.replace('£', '').replace(',', '');

    const transaction: any = {
      firstName: this.transactionForm.value.firstName,
      lastName: this.transactionForm.value.lastName,
      email: this.transactionForm.value.email,
      phone: this.transactionForm.value.areaCode + this.transactionForm.value.telephone,
      balance: parseFloat(balance),
      status: this.transactionForm.value.status,
      companyName: this.transactionForm.value.companyName,
    };
    this.loading = true;
    this.transactionService.updateTransaction(this.idTransaction, transaction).subscribe({
      next: data => {
        this.dialogRef.close(true);
        this.toastr.success('Transaction was updated successfully', 'Updated');
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.error(error);
      },
    });
  }

  error(error: HttpErrorResponse) {
    const errorMsg: string = error.error.code
      ? error.error.code
      : 'An error has occurred, please contact the administrator';
    this.toastr.error(errorMsg, 'Error!');
    this.loading = false;
  }

  getCountries() {
    this.utilitiesService.getListCountry().subscribe(data => {
      this.mapCountryList(data);
    });
  }

  mapCountryList(listCountry: CountryDataModel[]) {
    // We extract the column phoneCode
    let listCodeArea: string[] = this.utilitiesService.extractColumn(listCountry, 'phoneCode');

    // We remove duplicates
    listCodeArea = [...new Set(listCodeArea)];

    // We delete '+' - We need to sort by number
    listCodeArea = listCodeArea.map(x => x.substring(1));

    // We sort
    listCodeArea = listCodeArea.sort(this.compareNumbers);

    // We add '+' in the first position
    listCodeArea = listCodeArea.map(x => '+'.concat(x));

    // We delete the UK codeArea from the list
    listCodeArea = listCodeArea.filter(x => x !== '+44');

    // We push the UK codeArea in the first position
    listCodeArea.unshift('+44');

    this.codeAreas = listCodeArea;
  }

  // Sort by number
  compareNumbers(a: any, b: any) {
    return a - b;
  }
}
