<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="loading ? 'wrapperSpinner' : 'wrapper'" class="wrapper">
  <div class="loginImg">
    <img class="logo" src="../../../assets/img/logo.png" alt="Logo Oz" />
  </div>
  <form [formGroup]="loginForm" (ngSubmit)="logIn()" class="loginWrapper" [hidden]="showVerify">
    <h3 class="text-center o2Arena">{{ title }}</h3>
    <div class="loginForm">
      <div class="title">
        <h3 class="login">Log In</h3>
        <h2 class="welcome">Welcome Back!</h2>
      </div>
      <div class="mb-3 input">
        <label for="account" class="form-label">Account ID</label>
        <input
          type="text"
          class="form-control form-control-lg"
          formControlName="account"
          id="account"
          placeholder="Add your account ID"
          autocomplete="off" />
      </div>
      <div class="mb-3 input">
        <label for="password" class="form-label">Password</label>
        <input
          type="password"
          class="form-control form-control-lg"
          formControlName="password"
          id="password"
          placeholder="Add your password"
          autocomplete="off" />
        <small routerLink="/password-reset" class="forgot">Forgot Password?</small>
      </div>
      <button type="submit" class="btn btn-primary btn-lg btnLogin" [disabled]="loginForm.invalid">Log In</button>
    </div>
    <div>
      <p class="powered">{{ copyright }}</p>
    </div>
  </form>

  <form [formGroup]="verifyForm" (ngSubmit)="verify()" class="loginWrapper" [hidden]="!showVerify">
    <h3 class="text-center o2Arena">{{ title }}</h3>
    <div class="loginForm">
      <div class="title">
        <h3 class="login">Two Step Verification</h3>
        <h2 class="welcome">Please enter verification code</h2>
      </div>
      <div class="mb-3 input">
        <label for="verifyCode" class="form-label">Verification Code</label>
        <input
          type="text"
          class="form-control form-control-lg"
          formControlName="verifyCode"
          id="verifyCode"
          placeholder="Verification Code"
          autocomplete="off" />
      </div>
      <button type="submit" class="btn btn-primary btn-lg btnLogin" [disabled]="verifyForm.invalid">Verify</button>
    </div>
    <div>
      <p class="powered">{{ copyright }}</p>
    </div>
  </form>
</div>
